import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';
import { components } from 'openapi-types';

export type Backup = NonNullable<components['schemas']['ListBackupResponse']['result']>[number];

export type AutomatedBackup = NonNullable<
  components['schemas']['ListAutomatedBackupResponse']['result']
>[number];

const BACKUP_LIST_UPDATE_INTERVAL = 1000;

export const useGetBackups = (siteId: string, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['backups', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListBackupResponse']>(
        `sites/${siteId}/backup?${serializedParams}`
      ),

    refetchInterval: query =>
      query.state.data?.data.result?.some(
        backup =>
          backup.status === 'NEW' || backup.status === 'PROGRESS' || backup.status === 'RESTORING'
      )
        ? BACKUP_LIST_UPDATE_INTERVAL
        : false,
  });
};

export const useGetDownloadLink = (siteId: string) => {
  return useMutation({
    mutationKey: [`backups-tasks-${siteId}-link`],

    mutationFn: async (taskId: string) =>
      await AxiosService.get<components['schemas']['ListBackupResponse']>(
        `sites/${siteId}/backup?task_id=${taskId}`
      ),
  });
};

export type CreateBackupRequest = components['schemas']['CreateBackupRequest'];

export const useCreateBackup = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (postData: CreateBackupRequest) =>
      await AxiosService.post(`sites/${siteId}/backup`, postData),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['backups', siteId],
      }),
  });
};

export const useDeleteBackup = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (backupId: number) =>
      await AxiosService.delete<components['schemas']['SuccessResponse']>(
        `sites/${siteId}/backup/${backupId}`
      ),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['backups', siteId],
      }),
  });
};

export type RestoreBackupRequest = components['schemas']['RestoreBackupRequest'] & {
  backup_id: string;
};

export const useRestoreBackup = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (postData: RestoreBackupRequest) =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(
        `sites/${siteId}/backup/${postData.backup_id}/restore`,
        { backup_database: postData.backup_database, backup_directory: postData.backup_directory }
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['backups', siteId],
      }),
  });
};

export type RestoreAutomatedBackupFilesRequest =
  components['schemas']['RestoreAutomatedBackupFilesRequest'] & { backup_id: string };

export const useRestoreAutomatedBackupFiles = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ backup_id, files, restore_type }: RestoreAutomatedBackupFilesRequest) =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(
        `sites/${siteId}/backup/automated/${backup_id}/restore/files`,
        {
          files,
          restore_type,
        }
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automated-backups', siteId],
      }),
  });
};

export const useRestoreAutomatedBackupDatabase = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ backupId }: { backupId: string }) =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(
        `sites/${siteId}/backup/automated/${backupId}/restore/database`,
        {}
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automated-backups', siteId],
      }),
  });
};

export const useRestoreAutomatedBackup = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ restoreId }: { restoreId: string }) =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(
        `sites/${siteId}/backup/automated/${restoreId}/restore`,
        {}
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automated-backups', siteId],
      }),
  });
};

export const useGetAutomatedBackups = (siteId: string, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['automated-backups', siteId, serializedParams],

    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListAutomatedBackupResponse']>(
        `sites/${siteId}/backup/automated?${serializedParams}`
      ),

    refetchInterval: query =>
      query.state.data?.data.result?.some(backup => backup.status === 'RESTORING')
        ? BACKUP_LIST_UPDATE_INTERVAL
        : false,
  });
};
